import {LocaleCode, availableLocales} from '@legit.health/ui';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import userLocaleLocalStorage from '@/shared/userLocale/userLocaleLocalStorage';

const localStorageLocale = userLocaleLocalStorage.get();
if (localStorageLocale === 'es') {
  userLocaleLocalStorage.set(LocaleCode.Spanish);
} else if (localStorageLocale === 'en') {
  userLocaleLocalStorage.set(LocaleCode.English);
}

const userLang = navigator.language || LocaleCode.English;
const defaultLocale = availableLocales.map((l) => l.code as string).includes(userLang)
  ? userLang
  : LocaleCode.English;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: userLocaleLocalStorage.get() ?? defaultLocale,
    interpolation: {
      escapeValue: false
    },
    returnNull: false,
    fallbackLng: false,
    ns: [],
    defaultNS: 'shared/common',
    backend: {
      queryStringParams: {v: '1.4.0'}
    }
  });

export default i18n;
